import React from 'react';
import Helmet from 'react-helmet';
import defaultImg from '../../img/defaultImg.jpg';

const HelmetComponent = ({ title, description, image }) => {
  // this checks if description, title, and image is undefined.
  // if undefined, it will use the given text
  description = description
    ? description.description
    : 'Glory Reborn provides compassionate and holistic care to marginalized moms and babies of Cebu, Philippines.';
  title =
    'Glory Reborn - ' +
    (title ||
      'Glory Reborn provides compassionate and holistic care to marginalized moms and babies of Cebu, Philippines.');
  image = image ? image : defaultImg;
  return (
    <>
      <Helmet title={title}>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content={description} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta property="og:site_name" content="Glory Reborn" />
      </Helmet>
    </>
  );
};

export default HelmetComponent;
