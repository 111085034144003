import React from 'react';
import { graphql } from 'gatsby';

import { Widget } from '@typeform/embed-react';

import Layout from '../components/layout';

import ImgBtm from '../img/grc-ico-logo.svg';
import ImgLogo from '../img/logo-glory-reborn.png';

import HelmetComponent from '../components/helmetcomponent';

const pageUrls = require('../constants');


export default function Template({ data }) {
  
  

  return (
    <Layout nav="colored-nav">
      <HelmetComponent
        title="Celebrating Anniversary 2023"
        description="Celebrating our 20th Anniversary"
      />
      <div className='annivTop'>
      <a href='/'><img src={ImgLogo} alt="Glory Reborn" className="nav-logo" /></a>
      </div>
      <div className="anniv23">
        <section className='pageintro-wrap'>
          <div className='container'>
            <div className='pageintro-content text-center'>
              <h3>Thank you for your interest in Glory Reborn.</h3>
              <p>We are excited to be Celebrating our 20th Anniversary this year! We are grateful for partners like you who make <span>Healthy Moms</span>, <span>Healthy Babies</span> and <span>Hopeful Hearts</span> possible.</p>
            </div>  
          </div>
        </section>
        <section className='pageform-wrap'>
          <div className='container'>
            <Widget id="mENSjSRk" style={{ width: '100%' }} className="anniv-form" opacity={5} autoResize inlineOnMobile hideFooter  />
          </div>
          <p className='note text-center'>*This website is generated and registered in the United States of America under Glory Reborn Organization, Inc.*</p>
          <div className='btm-ico'><img src={ImgBtm} /></div>
        </section>
       
      </div>
      <style>
        {`
          footer, .navbar {
            display: none;
          }
        `}
      </style>
    </Layout>
  );
}

export const anniversary18PageQuery = graphql`
  query anniversary2023Page($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
    allContentfulGloryReborn18thAnniversary(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          totalAmount
        }
      }
    }
  }
`;
